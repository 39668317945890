import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "state";
import authReducer from "state/authSlice.js";
import emissionReducer from "state/emissionSlice.js";
import preferenceSlice from "state/preferenceSlice";
import reportSlice from "state/reportslice";
import { Provider } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
// import { api } from 'state/api';
import { ProSidebarProvider } from "react-pro-sidebar";

const store = configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    emission: emissionReducer,
    preference: preferenceSlice,
    report: reportSlice,
  },
});
setupListeners(store.dispatch);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProSidebarProvider>
        <App />
      </ProSidebarProvider>
    </Provider>
  </React.StrictMode>
);
