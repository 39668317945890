import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet, Navigate ,useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from 'components/Navbar';

// import { useGetUserQuery } from 'state/api';
import { useProSidebar } from 'react-pro-sidebar';
import CustomModal from 'components/react-modal/customModal';
import Profile from 'components/Profile';
import SustainextHQ from 'pages/Home/useronboarding';
import Clintpasswordreset from 'pages/auth/clint_password_reset';
import Sidebar1 from 'components/Sidebar';
const PrivateLayout = () => {
  const isNonMobile = useMediaQuery('(min-width: 600px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { collapsed } = useProSidebar();
  const userId = useSelector((state) => state.global.userId);
  const [isTourModeActive, setIsTourModeActive] = useState(false);
  // const { data } = useGetUserQuery(userId);
const newdata = parseInt(localStorage.getItem('auth'));
  const authToken = useSelector((state) => state.auth.authToken);

  const location = useLocation(); // Get the current route location

  if (authToken === null) {
    return <Navigate to='/login' replace />;
  }

  const handleProfileImgClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleProfileClick = () => {
    setModalIsOpen(!modalIsOpen);
    setDropdownOpen(!dropdownOpen);
  };
 // Check if the SustainextHQ should be displayed on the home page
//  const shouldDisplaySustainextHQ = location.pathname === '/home';
//  const isSidebarClicked = location.pathname.startsWith('/sidebar');

  return (
    <>
{newdata === 1 ? <Clintpasswordreset/> :
    <Box
      display={isNonMobile ? 'flex' : 'block'}
      width='auto'
      height='100%'
      sx={{
        marginLeft: collapsed ? '80px' : '250px',
        transitionProperty: 'all',
        transitionDuration: '0.3s',
        filter: isTourModeActive ? 'blur(5px)' : 'none',
      }}
    >
      <Sidebar1
        isNonMobile={isNonMobile}
        drawerWidth='250px'
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1} sx={{width:"100%"}}>
        <Navbar
          // user={data || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          onProfileClick={handleProfileClick}
          onProfileImgClick={handleProfileImgClick}
          dropdownOpen={dropdownOpen}
        />
       {/* {shouldDisplaySustainextHQ && <SustainextHQ />} */}
        <Outlet />
        {modalIsOpen && (
          <CustomModal onClose={handleCloseModal}>
            {/* <Profile /> */}
          </CustomModal>
        )}
      </Box>
    </Box>
}
    </>
  );
};

export default PrivateLayout;
