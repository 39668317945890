import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  CottageOutlined,
  KeyboardArrowDownOutlined,
  LocalShippingOutlined,
  PowerOutlined,
} from '@mui/icons-material';
import { KeyboardArrowUpOutlined } from '@mui/icons-material';
import exclaim from '../../assets/images/exclaimatory-sign.svg';
import compost from '../../assets/images/compost_black.svg';

function WasteExpandableComponent({ children, label, className, description }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const containerHeight = isExpanded ? 'auto' : 75;

  return (
    <motion.div
      className={`relative my-[2rem] me-[1.3rem] ${className}`}
      initial={{ height: 75 }}
      animate={{ height: containerHeight }}
      transition={{ duration: 0.3 }}
      style={{ overflow: 'hidden' }}
    >
      <button
        className='w-full h-[60px] text-neutral-500 text-xs font-bold leading-[15px] rounded-lg shadow-md border border-b-3 border-neutral-200 py-2 px-4 '
        onClick={handleButtonClick}
      >
        <div className='flex justify-center items-center relative'>
          {description}
          <div className='absolute left-4 flex items-center'>
            <span className='me-2'>
              {label === '' ? <img src={exclaim} /> : ''}
              {label === '' ? <img src={compost} /> : ''}
              {/* {label === 'Scope 3' ? <LocalShippingOutlined /> : ''} */}
            </span>
            {label}
          </div>
          <div className='absolute right-6'>
            {isExpanded ? (
              <KeyboardArrowUpOutlined />
            ) : (
              <KeyboardArrowDownOutlined />
            )}
          </div>
        </div>
      </button>
      <motion.div
        className='mt-4'
        initial={{ opacity: 0 }}
        animate={{ opacity: isExpanded ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
}

export default WasteExpandableComponent;
