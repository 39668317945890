import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
const Standardsoutside = () => {
    const { collapsed } = useProSidebar();
    return (
        <>
            <div className='mt-3'>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[65%]' : 'w-[80%]'}`}>
                            <h6 className='text-sm text-[#727272]'>Please report the standards used while compiling the information for Energy Consumption outside the organization.</h6>
                        </div>
                        <div className={`absolute  flex justify-between -mt-1 ${collapsed ? 'right-[11rem]' : 'right-[4.5rem]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-blue-800 text-[10px] inline-block align-middle px-2 font-semibold">GRI 302-1b</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'w-[48rem]' : 'w-[44.2rem]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[65%]' : 'w-[80%]'}`}>
                            <h6 className='text-sm text-[#727272]'>Please report the methodologies used while compiling the information for Energy Consumption
                                outside the organization.</h6>
                        </div>
                        <div className={`absolute  flex justify-between -mt-1 ${collapsed ? 'right-[11rem]' : 'right-[4.5rem]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-blue-800 text-[10px] inline-block align-middle font-semibold px-2">GRI 302-1b</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'w-[48rem]' : 'w-[44.2rem]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[65%]' : 'w-[80%]'}`}>
                            <h6 className='text-sm text-[#727272]'>Please report the assumptions used while compiling the information for Energy Consumption
                                outside the organization.</h6>
                        </div>
                        <div className={`absolute  flex justify-between -mt-1 ${collapsed ? 'right-[11rem]' : 'right-[4.5rem]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-blue-800 text-[10px] inline-block align-middle px-2 font-semibold">GRI 302-1b</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'w-[48rem]' : 'w-[44.2rem]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[65%]' : 'w-[80%]'}`}>
                            <h6 className='text-sm text-[#727272]'>Please report the calculation tools used while compiling the information for Energy Consumption
                                outside the organization.</h6>
                        </div>
                        <div className={`absolute  flex justify-between -mt-1 ${collapsed ? 'right-[11rem]' : 'right-[4.5rem]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-blue-800 text-[10px] inline-block align-middle px-2 font-semibold">GRI 302-1b</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'w-[48rem]' : 'w-[44.2rem]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default Standardsoutside;