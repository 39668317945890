import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  CheckOutlined,
  AddOutlined,
  FilePresent,
  ArrowDropDown,
  KeyboardArrowDownOutlined,
  FileUploadOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import PdfPreviewModal from "./PdfPreviewModal";
import { unitTypes } from "components/data/units";
import { useDispatch } from "react-redux";
import { setRowsState, selectRowsState } from "state/emissionSlice";

function WasteShowContent({ scope, scopeInfo }) {
  const countryCode = useSelector((state) => state.emission?.countryCode);
  const token = useSelector((state) => state.global.authToken);
  const dispatch = useDispatch();
  // Underline comes after selecting input
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedOption, setSelectedOption] = useState("1");
  //   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedActivity, setSelectedActivity] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const options = [
    'Method of disposal',
    '(Off-site) other (please specify)',
    'No Disposal',
    '(Off-site) External Vendor',
    // Add more options as needed
  ];

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectValue = (value) => {
    setSelectedValue(value);
    setIsDropdownOpen(false);
    // You can also call your handleChange function here if needed
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const rowsState = useSelector((state) => selectRowsState(state, scope));
  const currentUser = useSelector((state) => state.global.loginUser);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (scopeInfo.length > 0) {
      const initialRows = [
        {
          category: "",
          subCategory: "",
          activities: [],
          value1: "",
          value2: "",
          unit: [],
          file: null,
          fileName: "",
          modifiedTime: "",
          activity: "",
          selectedActivity: {},
          unitType: "",
          assignTo: "",
          uploadedBy: currentUser,
        },
      ];
      setRows(initialRows);
      dispatch(setRowsState({ scope: scope, rows: initialRows }));
    }
  }, [dispatch, scope, scopeInfo]);

  const handleAddRow = () => {
    const newRow = {
      category: "",
      subCategory: "",
      activities: [],
      value1: "",
      value2: "",
      unit: [],
      file: null,
      fileName: "",
      modifiedTime: "",
      activity: "",
      selectedActivity: {},
      unitType: "",
      assignTo: "",
      uploadedBy: currentUser,
    };
    setSelectedCategory("");
    setSelectedSubCategory("");
    setSelectedActivity("");

    setRows((prevRows) => [...prevRows, newRow]);
    const updatedRows = [...rows, newRow];
    dispatch(setRowsState({ scope: scope, rows: updatedRows }));
  };

  const handleChange = async (event, index, field) => {
    const newRows = JSON.parse(JSON.stringify(rows));
    newRows[index][field] = event.target.value;

    if (field === "category") {
      setSelectedCategory(event.target.value);
      setSelectedSubCategory("");
      setSelectedActivity("");
    } else if (field === "subCategory") {
      setSelectedSubCategory(event.target.value);
      setSelectedActivity("");
    } else if (field === "activity") {
      setSelectedActivity(event.target.value);
    }

    if (field === "subCategory") {
      const newSubCategory = event.target.value;
      newRows[index].subCategory = newSubCategory;

      try {
        // Fetch and update activities here (assuming you have it implemented correctly).
        const activitiesData = await fetchActivities(index, newSubCategory);
        newRows[index].activities = activitiesData;

        // Also, reset activity and assignTo fields when subCategory changes.
        newRows[index].activity = "";
        newRows[index].assignTo = "";
        newRows[index].unit = [];
      } catch (error) {
        console.error("Error fetching activities: ", error);
      }
    } else if (field === "activity") {
      const selectedUnitType =
        event.target.options[event.target.selectedIndex].dataset.unitType;
      newRows[index].activity = JSON.stringify(event.target.value);
      newRows[index].unitType = selectedUnitType || null;
      const activitySelected = newRows[index].activities.find(
        (activity) => activity.name === event.target.value
      );
      // console.log(activitySelected, 'activity selected', event.target.value);
      newRows[index].selectedActivity = activitySelected;
    } else if (field === "assignTo") {
      newRows[index].assignTo = event.target.value;
    } else if (field === "unit") {
      const selectedUnit = event.target.value;
      const allUnits = rows[index].unit;
      console.log(allUnits, selectedUnit);
      newRows[index].unit = [...allUnits, selectedUnit];
    } else if (field === "value1") {
      const selectedValue = event.target.value;
      newRows[index].value1 = selectedValue;
    } else if (field === "value2") {
      const selectedValue = event.target.value;
      newRows[index].value2 = selectedValue;
    }

    dispatch(setRowsState({ scope: scope, rows: newRows }));
    setRows(newRows);
  };

  const fetchedYear = useSelector((state) => state.emission?.year);
  const year = fetchedYear.substring(0, 4);
  const quarter = useSelector((state) => state.emission?.quarter);

  function getUniqueObjectsById(arr) {
    const uniqueObjects = {};
    const result = [];

    for (const obj of arr) {
      if (!uniqueObjects[obj.id]) {
        uniqueObjects[obj.id] = true;
        result.push(obj);
      }
    }

    return result;
  }

  async function fetchActivities(index, category) {
    const baseURL = "https://beta3.api.climatiq.io/search";
    const resultsPerPage = 235;
    const axiosConfig = {
      headers: {
        Authorization: "Bearer V4BX26K9GQ4GWYQ6XHABNY7C3NPS",
        Accept: "application/json",
        "Content-type": "application/json",
      },
    };

    const fetchEndpoint = (region) => {
      console.log(year, region, category);
      return axios.get(
        `${baseURL}?results_per_page=${resultsPerPage}&year=${year}&region=${region}&category=${category}`,
        axiosConfig
      );
    };

    try {
      const response = await fetchEndpoint(countryCode);
      if (response.data.results.length === 0) {
        const responses = await axios.all([fetchEndpoint("*")]);
        const combinedResults = responses.flatMap(
          (response) => response.data.results
        );
        const filteredData = getUniqueObjectsById(combinedResults);
        console.log(filteredData);
        return filteredData;
      }
      return response.data.results;
    } catch (error) {
      console.error("Error fetching data from different regions: ", error);
      throw error;
    }
  }

  //USERS

  const [users, setUsers] = useState([
    { username: "Dept Head", id: "1" },
    { username: "Line Manager", id: "2" },
    { username: "Emp 1", id: "3" },
    { username: "Emp 2", id: "4" },
    { username: "Emp 3", id: "5" },
  ]);

  // useEffect(() => {
  //   fetchUsers();
  // }, []);

  // function fetchUsers() {
  //   let axiosConfig = {
  //     headers: {
  //       Authorization: 'Bearer ' + token,
  //       'Content-type': 'application/json',
  //     },
  //   };
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_URL}/accounts/user`,
  //       { username: 'Riti25' },
  //       axiosConfig
  //     )
  //     .then((response) => {
  //       setUsers(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data: ', error);
  //     });
  // }

  //File upload
  const [file, setFile] = useState(null);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const handleFileChange = async (event, index) => {
    const uploadedFile = event.target.files[0];
    console.log(`Uploading file ${uploadedFile}`);
    if (uploadedFile) {
      const newRows = rows.map((row, i) => {
        if (i === index) {
          return {
            ...row,
            fileName: uploadedFile.name,
            modifiedTime: new Date().toLocaleString(),
          };
        }
        return row;
      });

      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = (event) => {
        const updatedRows = newRows.map((row, i) => {
          if (i === index) {
            return {
              ...row,
              file: event.target.result,
            };
          }
          return row;
        });
        dispatch(setRowsState({ scope: scope, rows: updatedRows }));
        console.log("dispatch row", updatedRows);
      };
      setIsUploaded(true);
      setTimeout(() => {
        setShowCheckmark(false);
      }, 2000);
    } else {
      const newRows = rows.map((row, i) => {
        if (i === index) {
          return {
            ...row,
            file: null,
            fileName: "",
          };
        }
        return row;
      });

      dispatch(setRowsState({ scope: scope, rows: newRows }));
      setShowCheckmark(false);
    }
  };

  // Modal for File preview
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewRowIndex, setPreviewRowIndex] = useState(null);

  const handleGreenIconClick = (index) => {
    setPreviewRowIndex(index);
    setShowPreviewModal(true);
  };

  const closeModal = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    if (isUploaded) {
      setShowCheckmark(true);
      const timer = setTimeout(() => {
        setShowCheckmark(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isUploaded]);

  // Fetch Units
  const [units, setUnits] = useState(unitTypes);
  // useEffect(() => {
  //   fetchUnits();
  // }, []);

  function fetchUnits() {
    const baseURL = "https://beta4.api.climatiq.io";
    const resultsPerPage = 100;
    const axiosConfig = {
      headers: {
        Authorization: "Bearer V4BX26K9GQ4GWYQ6XHABNY7C3NPS",
        Accept: "application/json",
        "Content-type": "application/json",
      },
    };
    axios
      .get(`${baseURL}/unit-types`, axiosConfig)
      .then((response) => {
        setUnits(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

  // Assign

  const handleAssign = (index) => {
    const row = rows[index];
    console.log("Category:", row.category);
    console.log("Sub-Category:", row.subCategory);
    console.log("Activity:", row.activity);
    console.log("Assign to:", row.assignTo);
  };

  return (
    <div className="bg-white rounded-lg shadow border border-neutral-200 py-4 px-2 show">
      <div>
        <div className="w-12 h-6 flex-col justify-center items-start inline-flex">
          <span className="">
            <div className="h-4 px-1 py-0.5 opacity-80 bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] rounded-sm justify-start items-start gap-2.5 inline-flex ms-5">
              <div className="text-white text-[11px] font-bold uppercase leading-none">
                {quarter}
              </div>
            </div>
          </span>
        </div>
      </div>
      <table className="mb-5 w-full text-xs text-start mt-3 text-[#707070]">
        <tbody>
          {rowsState.map((row, index) => (
            <tr key={index} className="border-b border-[#EDEAE9]">
              <td className="w-[10.25%]">
                <div className="relative">
                  <select
                    value={row.category}
                    onChange={(event) => handleChange(event, index, "category")}
                    className="cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full truncate"
                  >
                    <option value="">Waste type</option>
                    <option value="Chemical Waste">Chemical Waste</option>
                    <option value="Used Oil">Used Oil</option>
                    <option value="E-Waste">E-Waste</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
                    <KeyboardArrowDownOutlined
                      className="text-neutral-500"
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                  {selectedCategory && (
                    <div
                      className="absolute left-0 right-0 bottom-0 h-px mb-1 bg-gray-500"
                      // You can adjust the styles and colors of the underline as needed
                    />
                  )}
                </div>
              </td>
              <td className="w-[10.25%]">
      <div className="relative">
        <div className="custom-select">
          <button
            className="cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full truncate"
            onClick={handleToggleDropdown}
          >
            {selectedValue || 'Method of disposal'}
          </button>
          {isDropdownOpen && (
            <div className="custom-dropdown">
              {options.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectValue(option)}
                  className="cursor-pointer bg-white px-4 py-2 rounded leading-tight hover:bg-gray-100"
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </td>

              <td className="w-[15.25%]">
                <div className="relative">
                  <select
                    value={row.value}
                    onChange={(event) => handleChange(event, index, "activity")}
                    className="cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full"
                  >
                    <option value="">Recovery method</option>
                    {row.activities?.map((item) => (
                      <option
                        key={item.name}
                        data-unit-type={item.unit_type}
                        value={item.name}
                      >
                        {item.name} - ( {item.source} ) - {item.unit_type}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 -right-2 flex items-center pointer-events-none">
                    <KeyboardArrowDownOutlined
                      className="text-neutral-500"
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                  {selectedActivity && (
                    <div
                      className="absolute left-0 right-0 bottom-0 h-px mb-1 bg-gray-500"
                      // You can adjust the styles and colors of the underline as needed
                    />
                  )}
                </div>
                <div className="border h"></div>
              </td>

              {!row.unitType.includes("Over") ? (
                <td className="relative w-[20.21%]">
                  <div className="flex w-full">
                    <div className="flex-grow">
                      <input
                        type="number"
                        className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                        value={row.value1}
                        onChange={(event) =>
                          handleChange(event, index, "value1")
                        }
                      />
                    </div>
                    <div className="absolute right-0 top-0.5">
                      <select
                        value={row.value}
                        onChange={(event) => handleChange(event, index, "unit")}
                        className={`cursor-pointer appearance-none px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                          row.unit[0]
                            ? "text-sky-600 bg-white drop-shadow-md"
                            : "text-gray-400"
                        }`}
                        style={{ width: "66px" }}
                      >
                        <option className="text-xs">Unit</option>
                        {row.activity &&
                          row.unitType &&
                          units
                            .filter((unit) => unit.unit_type === row.unitType)
                            .reduce((combinedUnits, unit) => {
                              return combinedUnits.concat(
                                Object.values(unit.units)
                              );
                            }, [])
                            .flat()
                            .map((unitName) => (
                              <option key={unitName} className="text-xs">
                                {unitName}
                              </option>
                            ))}
                      </select>
                      <span className="absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none">
                        <ArrowDropDown
                          className={`text-xs ${
                            row.unit[0] ? "text-sky-600" : "text-white "
                          }`}
                        />
                      </span>
                    </div>
                  </div>
                </td>
              ) : (
                <td className="w-[15.21%]">
                  <div className="flex items-center">
                    <div className="relative w-1/2">
                      <input
                        type="number"
                        value={row.value1}
                        onChange={(event) =>
                          handleChange(event, index, "value1")
                        }
                        className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                      />
                      <div className="absolute right-0 top-0.5">
                        <select
                          value={row.value}
                          onChange={(event) =>
                            handleChange(event, index, "unit")
                          }
                          className={`cursor-pointer appearance-none  px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                            row.unit[0]
                              ? "text-sky-600 bg-white drop-shadow-md"
                              : "text-gray-400"
                          } w-[66px]`}
                          style={{ width: "66px" }}
                        >
                          <option className="text-xs">Unit</option>
                          {row.activity &&
                            row.unitType &&
                            units
                              .filter((unit) => unit.unit_type === row.unitType)
                              .reduce((combinedUnits, unit) => {
                                return combinedUnits.concat(
                                  Object.values(unit.units)
                                );
                              }, [])
                              .flat()
                              .map((unitName) => (
                                <option key={unitName} className="text-xs">
                                  {unitName}
                                </option>
                              ))}
                        </select>
                        <span className="absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none">
                          <ArrowDropDown
                            className={`text-xs ${
                              row.unit[0] ? "text-sky-600" : "text-white "
                            }`}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="relative w-1/2 ">
                      <input
                        type="number"
                        className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                        value={row.values2}
                        onChange={(event) =>
                          handleChange(event, index, "value2")
                        }
                      />
                      <div className="absolute right-0 top-0.5">
                        <select
                          value={row.value}
                          onChange={(event) =>
                            handleChange(event, index, "unit")
                          }
                          className={`cursor-pointer appearance-none  px-2 py-1 rounded-md leading-tight outline-none ms-1 mt-1.5 font-bold text-xs ${
                            row.unit[1]
                              ? "text-sky-600 bg-white drop-shadow-md"
                              : "bg-sky-600 text-white"
                          }`}
                          style={{ width: "66px" }}
                        >
                          <option className="text-xs">Unit</option>
                          {row.activity &&
                            row.unitType &&
                            units
                              .filter((unit) => unit.unit_type === row.unitType)
                              .reduce((combinedUnits, unit) => {
                                return combinedUnits.concat(
                                  Object.values(unit.units)
                                );
                              }, [])
                              .flat()
                              .map((unitName) => (
                                <option key={unitName} className="text-xs">
                                  {unitName}
                                </option>
                              ))}
                        </select>
                        <span className="absolute right-2 top-4 transform -translate-y-1/2 pointer-events-none">
                          <ArrowDropDown
                            className={`text-xs ${
                              row.unit[1] ? "text-sky-600" : "text-white "
                            }`}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              )}

              {/* TETSTSTSTSTTS BEGINS  */}

              <td className="w-[10.25%]">
                Waste generated
                <div className="relative">
                  {/* <select
                    value={row.value}
                    onChange={(event) => handleChange(event, index, "activity")}
                    className="cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full"
                  > */}
                  {/* <option value="">Recovery method</option> */}
                  <div className="flex-grow">
                    <input
                      type="number"
                      className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                      value={row.value1}
                      onChange={(event) => handleChange(event, index, "value1")}
                    />
                  </div>

                  {/* {row.activities?.map((item) => (
                      <option
                        key={item.name}
                        data-unit-type={item.unit_type}
                        value={item.name}
                      >
                        {item.name} - ( {item.source} ) - {item.unit_type}
                      </option>
                    ))} */}
                  {/* </select> */}
                  {/* <div className="absolute inset-y-0 -right-2 flex items-center pointer-events-none">
                    <KeyboardArrowDownOutlined
                      className="text-neutral-500"
                      style={{ fontSize: "20px" }}
                    />
                  </div> */}
                  {selectedActivity && (
                    <div
                      className="absolute left-0 right-0 bottom-0 h-px mb-1 bg-gray-500"
                      // You can adjust the styles and colors of the underline as needed
                    />
                  )}
                </div>
              </td>
              <td className="w-[10.25%]">
                Waste diverted from disposal
                <div className="relative">
                  <div className="flex-grow">
                    <input
                      type="number"
                      className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                      value={row.value1}
                      onChange={(event) => handleChange(event, index, "value1")}
                    />
                  </div>
                  {/* <div className="absolute inset-y-0 -right-2 flex items-center pointer-events-none">
                    <KeyboardArrowDownOutlined
                      className="text-neutral-500"
                      style={{ fontSize: "20px" }}
                    />
                  </div> */}
                  {selectedActivity && (
                    <div
                      className="absolute left-0 right-0 bottom-0 h-px mb-1 bg-gray-500"
                      // You can adjust the styles and colors of the underline as needed
                    />
                  )}
                </div>
              </td>

              <td className="w-[10.25%]">
                Recovery Method
                <div className="relative">
                  {/* <select
                    value={row.value}
                    onChange={(event) => handleChange(event, index, "activity")}
                    className="cursor-pointer appearance-none bg-white px-4 py-2 rounded leading-tight outline-none m-[3px] w-full"
                  > */}
                  {/* <option value="">Recovery method</option> */}
                  <div className="flex-grow">
                    <input
                      type="number"
                      className="w-full p-2 rounded-sm mt-1 ms-2 alignment"
                      value={row.value1}
                      onChange={(event) => handleChange(event, index, "value1")}
                    />
                  </div>

                  {/* {row.activities?.map((item) => (
                      <option
                        key={item.name}
                        data-unit-type={item.unit_type}
                        value={item.name}
                      >
                        {item.name} - ( {item.source} ) - {item.unit_type}
                      </option>
                    ))} */}
                  {/* </select> */}
                  {/* <div className="absolute inset-y-0 -right-2 flex items-center pointer-events-none">
                    <KeyboardArrowDownOutlined
                      className="text-neutral-500"
                      style={{ fontSize: "20px" }}
                    />
                  </div> */}
                  {selectedActivity && (
                    <div
                      className="absolute left-0 right-0 bottom-0 h-px mb-1 bg-gray-500"
                      // You can adjust the styles and colors of the underline as needed
                    />
                  )}
                </div>
              </td>

              {/* TETSTSTSTSTTS  ENDS*/}

              <td className="relative w-[8.26%]">
                <label
                  htmlFor={`fileInput-${index}`}
                  className="left-2 top-0 cursor-pointer"
                >
                  <div className="flex items-center justify-center h-10">
                    {showCheckmark ? (
                      <CheckOutlined className="w-6 h-6 text-green-500" />
                    ) : row.file ? (
                      <div className="flex items-center">
                        <FilePresent
                          className="w-6 h-6 mr-1 text-gray-400"
                          style={{ color: "green" }}
                          onClick={() => handleGreenIconClick(index)}
                        />
                        <div className="w-[56px] truncate text-sky-600">
                          {row.fileName}
                        </div>
                      </div>
                    ) : (
                      <>
                        <FileUploadOutlined className="w-6 h-6 text-sky-600 hover:text-gray-700 cursor-pointer" />
                        <span className="text-sky-600 ms-1.5">
                          {row.file ? row.fileName : "Upload"}
                        </span>
                      </>
                    )}
                  </div>
                </label>
                <input
                  id={`fileInput-${index}`}
                  type="file"
                  className="hidden"
                  onChange={(event) => handleFileChange(event, index)}
                  disabled={row.file}
                  value={row.file ? "" : undefined}
                />

                <PdfPreviewModal
                  isOpen={showPreviewModal && previewRowIndex === index}
                  onClose={closeModal}
                  file={row.file}
                  fileName={row.fileName}
                  modifiedTime={row.modifiedTime}
                  row={row}
                  scope={scope}
                  uploadedBy={row.uploadedBy}
                />
              </td>
              {/* <td className='w-[6.32%]'>
                <div className='flex ml-2'>
                  <div className='w-[85px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-l flex-col justify-center items-center inline-flex'>
                    <div className='justify-center items-center gap-2 inline-flex'>
                      <div className='relative text-white text-[13px] font-medium leading-snug tracking-wide'>
                        Assign to
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-r'>
                    <div className='relative inline-flex'>
                      <div className='absolute -mr-2 inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                        <ArrowDropDown className='text-white bg-blue' />
                      </div>
                      <select
                        value={row.value}
                        onChange={(event) =>
                          handleChange(event, index, 'assignTo')
                        }
                        className='flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-r cursor-pointer appearance-none focus:outline-none text-white bg-blue hover:bg-light-blue-300'
                      >
                        <option value='' className='text-white pe-1'></option>
                        {users?.map(({ username }) => (
                          <option key={username}>{username}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </td> */}
              <td className="w-[2.32%]">
                <DeleteOutline className="text-red-600" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="font-bold py-2 px-4 rounded text-xs opacity-70 text-sky-600"
        onClick={handleAddRow}
      >
        <AddOutlined style={{ fontSize: "20px", marginBottom: "3px" }} /> Add
        new
      </button>
    </div>
  );
}

export default WasteShowContent;
