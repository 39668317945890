import React, { useState, useEffect, useMemo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import axios from "axios";
import Moment from "react-moment";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

const Datatable = ({ data, defaultItemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [totalPages, setTotalPages] = useState(0);
  // const [loopen, setLoOpen] = useState(false);
  const [sort, setSort] = useState({ column: null, direction: "asc" });
  const [sortedColumn, setSortedColumn] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const handleCheckboxChange = (status) => {
    setStatusFilter((prev) => ({
      ...prev,
      [status]: !prev[status],
    }));
  };

  const getFilteredData = () => {
    const filterKeys = Object.keys(statusFilter).filter(
      (key) => statusFilter[key]
    );
    if (filterKeys.length === 0) return data;
    return data.filter((item) => filterKeys.includes(String(item.task_status)));
  };

  // Function to toggle sort direction and column
  const handleSort = (column) => {
    const isAsc = sort.column === column && sort.direction === "asc";
    setSort({ column, direction: isAsc ? "desc" : "asc" });
  };

  // Sorting data
  const sortedData = useMemo(() => {
    const sortableItems = getFilteredData();
    if (!sort.column) {
      return sortableItems;
    }
    return sortableItems.sort((a, b) => {
      if (a[sort.column] < b[sort.column]) {
        return sort.direction === "asc" ? -1 : 1;
      }
      if (a[sort.column] > b[sort.column]) {
        return sort.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [data, sort, statusFilter]);

  const currentItems = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // const LoaderOpen = () => {
  //   setLoOpen(true);
  // };
  // const LoaderClose = () => {
  //   setLoOpen(false);
  // };

  // Function to handle the download

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / itemsPerPage));
  }, [data.length, itemsPerPage]);

  useEffect(() => {
    if (data && data.length > 0) {
      handleSort(sortedColumn); // Apply sorting on initial render or data change
    }
  }, [data, sortedColumn]);

  // Calculate the current items to displ
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Change items per page
  const onItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page
  };

  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, data.length);
  const handleSetdata = (
    location,
    month,
    scope,
    category,
    subcategory,
    task_name,
    year,
    assign_to_user_name,
    factor_id,
  ) => {
    const newdata = {
      location: location,
      month: month,
      scope: scope,
      category: category,
      subcategory: subcategory,
      activity: task_name,
      year: year,
      assign_to_user_name:assign_to_user_name,
      factor_id:factor_id,
    };

    navigate(`/collect/environment`, { state: { data: newdata } });
  };
  return (
    <>
      <div>
        <table className="table-fixed min-w-full shadow-md rounded-md w-[25%]">
          <thead className="py-3 px-6 text-left text-neutral-500 text-[13px] font-extrabold leading-none table-hader">
            <tr>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("task_name")}
              >
                Task{" "}
                {sort.column === "task_name" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("Location")}
              >
                Location{" "}
                {sort.column === "Location" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("month")}
              >
                Month & Year{" "}
                {sort.column === "month" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("assign_to_user_name")}
              >
                Assigned to{" "}
                {sort.column === "assign_to_user_name" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}{" "}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("created_at")}
              >
                Assigned on{" "}
                {sort.column === "created_at" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}{" "}
              </th>
              <th
                className="py-3 px-6 text-left whitespace-nowrap font-extrabold"
                onClick={() => handleSort("deadline")}
              >
                Due Date{" "}
                {sort.column === "deadline" ? (
                  sort.direction === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  <KeyboardArrowDownIcon />
                )}{" "}
              </th>
              <th className="py-3 px-6 text-left whitespace-nowrap font-extrabold">
                Status <FilterAltIcon onClick={() => setIsOpen(!isOpen)} />
              </th>
              {/* {columns.map((columns) => (
                <th  key={columns.accessor}
                onClick={() => handleSort(columns.accessor)}className="py-3 px-6 text-left whitespace-nowrap font-extrabold">
                  {columns.Header}     {sortedColumn === columns.accessor ? (
                  sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                ) : (
                  <>
                    <KeyboardArrowDownIcon />
                  </>
                )}
                </th>
              ))} */}

              {/* Other columns */}
            </tr>
            {isOpen && (
              <div className="absolute right-1 mt-2 w-[11.3rem] bg-white shadow-xl z-10">
                {Object.keys(statusFilter).map((status) => (
                  <div className="px-3 mb-1">
                    <label
                      className="flex items-center text-gray-600"
                      key={status}
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5"
                        checked={statusFilter[status]}
                        onChange={() => handleCheckboxChange(status)}
                      />
                      <span className="ml-2 text-sm pb-[0.5rem]">
                        <FiberManualRecordIcon
                          sx={{
                            fontSize: "1rem",
                            color:
                              status === "1"
                                ? "#006622"
                                : status === "2"
                                ? "#ff8000"
                                : status === "0"
                                ? "#ffd633"
                                : status === "3"
                                ? "#00cc00"
                                : status === "4"
                                ? "#e63900"
                                : "",
                          }}
                        />
                        {status === "1"
                          ? "Approved"
                          : status === "2"
                          ? "Under review"
                          : status === "0"
                          ? "InProgress"
                          : status === "3"
                          ? "Completed"
                          : status === "4"
                          ? "Rejected"
                          : ""}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {data.length > 0 &&
              currentItems.map((item, index) => (
                <tr key={index} className="border-b border-gray-200 text-left">
                  <td className="py-3 px-6 text-left whitespace-nowrap text-sky-700 font-medium w-56 truncate cursor-pointer" onClick={() => handleSetdata(item.location,item.month,item.scope,item.category,item.subcategory,item.task_name,item.year,item.assign_to_user_name,item.factor_id)}>
                    <Tooltip
                      title={item.task_name}
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#000",
                            color: "white",
                            fontSize: "12px",
                            boxShadow: 3,
                            borderRadius: "8px",
                            marginLeft: "-250px",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#000",
                          },
                        },
                      }}
                    >
                      {item.task_name}
                    </Tooltip>
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap text-black font-medium">
                    {item.location}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap text-black font-medium">
                    {item.month} {item.year}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap text-sky-700 font-medium w-56 truncate">
                    {item.assign_to_user_name}
                  </td>

                  <td className="py-3 px-6 text-left whitespace-nowrap text-black font-medium">
                    <Moment format="DD/MM/YYYY">{item.created_at}</Moment>
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap text-black font-medium">
                    {item.deadline}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap text-black font-semibold text-[0.85rem]">
                    <FiberManualRecordIcon
                      sx={{
                        fontSize: "1rem",
                        color:
                          item.task_status === 1
                            ? "#006622"
                            : item.task_status === 2
                            ? "#ff8000"
                            : item.task_status === 0
                            ? "#ffd633"
                            : item.task_status === 3
                            ? "#00cc00"
                            : item.task_status === 4
                            ? "#e63900"
                            : "",
                      }}
                    />{" "}
                    {item.task_status === 0
                      ? "InProgress"
                      : item.task_status === 1
                      ? "Approved"
                      : item.task_status === 2
                      ? "Under review"
                      : item.task_status === 3
                      ? "Completed"
                      : item.task_status === 4
                      ? "Rejected"
                      : ""}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="justify-end items-center gap-2 flex w-[100%] mt-4">
          <div>
            <label className="text-black text-opacity-60 text-xs font-normal leading-[15px] text-[15px]">
              Rows per page:
            </label>
            <select
              value={itemsPerPage}
              onChange={onItemsPerPageChange}
              className="text-black  text-xs font-normal leading-[15px]"
            >
              {[5, 10, 15, 20].map((number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              ))}
            </select>
          </div>

          <div className="ml-4 flex">
            <div>
              <span className="text-black  text-xs font-normal leading-[15px] text-[15px]">{`${firstItemIndex}-${lastItemIndex} of ${data.length}`}</span>
            </div>

            <div className="ml-4 mt-1">
              <button
                onClick={() => paginate(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
                className="text-black   font-normal leading-[15px] text-[25px]"
              >
                {"<"}
              </button>
              <button
                onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
                disabled={currentPage === totalPages}
                className="text-black  font-normal leading-[15px] text-[25px]"
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*      
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </>
  );
};

export default Datatable;
