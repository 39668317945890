import React, { useState, useEffect, useRef } from "react";
// import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// import Datepicker from 'react-tailwindcss-datepicker';
import TvIcon from "@mui/icons-material/Tv";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PrintIcon from "@mui/icons-material/Print";
import { Link } from "react-router-dom";
import Report from "./index";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "./Data-table/Datatable";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import Moment from "react-moment";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  DeleteOutlineOutlined,
  DownloadOutlined,
  EditOutlined,
  ArrowBackIosNewOutlined,
} from "@mui/icons-material";

const date = moment();

const Reportform = ({
  data,
  setTableData,
  handleCloseModal,
  onCreateReport,
  onSubmit,
}) => {
  const name = useSelector((state) => state.report.name);
  const reportType = useSelector((state) => state.report.reportType);
  const startDate = useSelector((state) => state.report.startDate);
  const endDate = useSelector((state) => state.report.endDate);
  const lastReport = useSelector((state) => state.report.lastReport);
  const [filteredName, setFilteredName] = useState([]);
  const [filteredReportType, setFilteredReportType] = useState([]);
  const [filteredStartDate, setFilteredStartDate] = useState([]);
  const [filteredEnddate, setFilteredEnddate] = useState([]);
  const [filteredLastReport, setFilteredLastReport] = useState([]);
  const [isExpandednext, setIsExpandednext] = useState(false);
  const [isExpandedpage, setIsExpandedpage] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [reportname, setReportname] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [reporttype, setReporttype] = useState("");
  const [reportby, setReportby] = useState("");
  const [firstSelection, setFirstSelection] = useState("");
  const [showSecondSelect, setShowSecondSelect] = useState(false);
  const [orgselectdata, setOrgselectdata] = useState("");
  const [corporateselect, setCorporateselect] = useState("");
  const [orgdata, setOrgdata] = useState();
  const [corporatedata, setCorpoatedata] = useState();
  const isMounted = useRef(true);
  const [loopen, setLoOpen] = useState(false);
  const handleFirstSelectChange = (event) => {
    setFirstSelection(event.target.value);
    setShowSecondSelect(true); // Show the second select box when an option is selected
  };
  const handleOrgselect = (event) => {
    setOrgselectdata(event.target.value);
    // console.log(event.target.value,"orgdata");
    // Show the second select box when an option is selected
  };
  const handleCorpselect = (event) => {
    setCorporateselect(event.target.value);
    // console.log(event.target.value,"orgdata");
    // Show the second select box when an option is selected
  };
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const fetchMygoleDetails = async () => {
    LoaderOpen();
    const userId = localStorage.getItem("user_id");
    // console.log("user id ", localStorage.getItem("user_id"));
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL
      }//user_org?username=${localStorage.getItem("email")}`
    );

    setOrgdata(response.data.org_data);
    setCorpoatedata(response.data.corp_data);

    LoaderClose();
  };
  useEffect(() => {
    if (isMounted.current) {
      fetchMygoleDetails();
      isMounted.current = false;
    }
    return () => {
      isMounted.current = false;
    };
    //  fetchMygoleDetails();
  }, []);
  const renderSecondSelect = () => {
    if (firstSelection === "Organization") {
      return (
        <div className="grid grid-cols-1">
          <select
            className="block w-full rounded-md border-0 py-2 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
            onChange={handleOrgselect}
            value={orgselectdata}
          >
            <option>Select Organization</option>
            {orgdata.map((orgdata) => (
              <>
                <option value={orgdata.id}>{orgdata.name}</option>
              </>
            ))}
            {/* Define options related to option1 */}
          </select>
        </div>
      );
    } else if (firstSelection === "Corporate") {
      return (
        <>
          <div className="grid grid-cols-2">
            <div className="mr-2">
              <select
                className="block w-full pl-4 rounded-md border-0 py-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                onChange={handleOrgselect}
                value={orgselectdata}
              >
                <option>Select Organization</option>
                {orgdata.map((orgdata) => (
                  <>
                    <option value={orgdata.id}>{orgdata.name}</option>
                  </>
                ))}
                {/* Define options related to option1 */}
              </select>
            </div>
            <div className="ml-2">
              <select className="block w-full pl-4 rounded-md border-0 py-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"  onChange={handleCorpselect}
                value={corporateselect}>
                <option>Select Corporate</option>
                {corporatedata.map((corporatedata) => (
                  <>
                    <option value={corporatedata.corporate_id}>
                      {corporatedata.corporate_name}
                    </option>
                  </>
                ))}
                {/* Define options related to option2 */}
              </select>
            </div>
          </div>
        </>
      );
    } else {
      return null; // Return null if no option or an unknown option is selected
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    Reporttype: "Monthly",
    startdate: "",
    enddate: "",
    Lastreport: moment().format("YYYY-MM-DD"),
    reportby: "ACME Group",
    action: [<DeleteOutlineOutlined />, <EditOutlined />],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsExpandednext(!isExpandednext);
    onSubmit(formData);
  };

  const handleClick = () => {
    setTableData((prev) => [...prev, formData]);
    setIsExpandednext(!isExpandednext);
    setIsExpandedpage(!isExpandedpage);
    // setTableData((prev) => [...prev, formData]);
  };

  console.log(isExpandednext);

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  const handleChangeName = (event) => {
    setReportname(event.target.value);
  };
  const handleChangeStartdate = (event) => {
    setStartdate(event.target.value);
  };
  const handleChangeEnddate = (event) => {
    setEnddate(event.target.value);
  };
  const handleChangeReporttype = (event) => {
    setReporttype(event.target.value);
  };
  const handleChangeReportby = (event) => {
    setReportby(event.target.value);
  };
  const submitForm = async (e) => {
    e.preventDefault();
    LoaderOpen();


    const sandData = {
   name:reportname,
   report_type:reporttype,
   report_by:firstSelection,
   start_date:startdate,
   end_date:enddate,
   organization:orgselectdata,
   corporate:corporateselect,
   user:parseInt(localStorage.getItem("user_id")),
    };
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/report_create`, sandData)
      .then((response) => {
        if (response.status == 201) {
          console.log(response.status);
          toast.success("Report has been added successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          LoaderClose();
          handleCloseModal();
        
   
        } else {
          toast.error("Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
        }
      });
    //console.log(sandData);
  };
  return (
    <>
      
      {isExpandedpage ? (
        <>
        <ToastContainer style={{ fontSize: "12px" }} />
          <div className="flex justify-between items-center drop-shadow-lg border-b-2 pt-6 w-full">
            <h2 className="self-stretch text-black text-opacity-90 text-[22px] font-normal leading-relaxed flex space-x-8 items-center ms-6">
              <span>New Report</span>
            </h2>
            <button
              className="absolute top-2 right-2 mt-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={handleCloseModal}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="mt-6 mx-8 ">
            {/* <h3 className="text-neutral-400 text-xs font-semibold uppercase leading-relaxed tracking-wide">
              Report Details
            </h3> */}
            <div className="mb-2 pt-4 px-3">
              <div>
                <form className="w-full text-left" onSubmit={submitForm}>
                  <div className="mr-2 mb-4 w-[101%]">
                    <label
                      htmlFor="cname"
                      className="block text-neutral-800 text-[13px] font-normal"
                    >
                      Report Name
                    </label>
                    <div className="mt-2 mr-2">
                      <input
                        id="cname"
                        name="name"
                        type="text"
                        autoComplete="cname"
                        value={reportname}
                        onChange={handleChangeName}
                        required
                        placeholder="Report Name"
                        className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 mb-4">
                    <div className="mr-2">
                      <label
                        htmlFor="sdate"
                        className="block text-neutral-800 text-[13px] font-normal"
                      >
                        Select Type Of Report
                      </label>
                      <div className="mt-2 mr-2">
                        <select
                          className="block w-full rounded-md border-0 py-2 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleChangeReporttype}
                          value={reporttype}
                          name="Reporttype"
                        >
                           <option>Select Report Type</option>
                          <option>GHG Accounting Report</option>
                          {/* <option>GRI</option>
                          <option>TCFD</option>
                          <option>SASB</option>
                          <option>BRSR</option> */}
                        </select>
                      </div>
                    </div>

                    <div className="ml-2">
                      <label
                        htmlFor="cname"
                        className="block text-neutral-800 text-[13px] font-normal"
                      >
                        Report by
                      </label>
                      <div className="mt-2">
                        <select
                          className="block w-full rounded-md border-0 py-2 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleFirstSelectChange}
                          value={firstSelection}
                        >
                          <option>Select option</option>
                          <option>Organization</option>
                          <option>Corporate</option>
                        </select>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    {showSecondSelect && renderSecondSelect()}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 mb-4">
                    <div>
                      <label
                        htmlFor="sdate"
                        className="block text-neutral-800 text-[13px] font-normal"
                      >
                        Reporting Period (From)
                      </label>
                      <div className="mt-2 mr-4">
                        <input
                          id="sdate"
                          name="startdate"
                          type="date"
                          autoComplete="sdate"
                          value={startdate}
                          onChange={handleChangeStartdate}
                          required
                          placeholder="Select Fiscal Year"
                          className="block w-full px-1 rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="ml-3 w-full">
                      <label
                        htmlFor="edate"
                        className="block text-neutral-800 text-[13px] font-normal"
                      >
                        Reporting Period (To)
                      </label>
                      <div className="mt-2 mr-3">
                        <input
                          id="edate"
                          name="enddate"
                          type="date"
                          autoComplete="edate"
                          value={enddate}
                          onChange={handleChangeEnddate}
                          required
                          placeholder="End date"
                          className="block w-full px-1 rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-10">
                    <div className="">
                   
                      <input
                          type="submit"
                          value="Create Report"
                          className="w-[80%] h-[31px] mb-2 px-[22px] py-2 bg-sky-600 rounded shadow flex-col justify-center items-center inline-flex cursor-pointer text-white"
                          // onClick={handleSave}
                        />
                    </div>
                  </div>
                  {/* onClick={handleClick} */}
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {isExpandednext ? (
        <>
          <Report data={[...data, formData]} />
        </>
      ) : (
        <></>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default Reportform;
