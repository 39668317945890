import React, { useState, useEffect, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { CloseOutlined, Delete } from '@mui/icons-material';

const PdfViewer = ({
  file,
  fileName,
  modifiedTime,
  uploadedBy,
  handleDelete,
  close,
}) => {
  const [refresh, setRefresh] = useState(false);
  const containerRef = useRef(null); // Initialize containerRef
  const canvasRefs = useRef([]);
  const renderTasksRef = useRef([]); // Ref to keep track of render tasks

  const { pdfDocument, pdfPage } = usePdf({
    file: file,
  });

  const getFileDetails = () => {
    if (file) {
      const fileBlob = dataURItoBlob(file);
      const fileExtension = fileBlob.type.split('/')[1];

      return {
        name: fileName,
        type: fileBlob.type,
        size: fileBlob.size,
        lastModified: modifiedTime,
        uploadedBy:
          uploadedBy === '9e3ca555b23f08cc8f4ca3525b1d241bbc0fde04'
            ? 'Test3'
            : 'No User Found',
      };
    }
    return null;
  };

  // Helper function to convert base64 string to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const fileDetails = getFileDetails();

  const renderPdfPages = async () => {
    if (!pdfDocument) return;

    const container = containerRef.current;
    renderTasksRef.current.forEach((task) => task.cancel()); // Cancel any ongoing render tasks
    renderTasksRef.current = [];

    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
      const page = await pdfDocument.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = canvasRefs.current[pageNumber - 1];
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = { canvasContext: context, viewport: viewport };
      const renderTask = page.render(renderContext);
      renderTasksRef.current.push(renderTask); // Store the render task

      await renderTask.promise; // Wait for the render to complete before continuing
    }

    // Update container height after all pages are rendered
    const totalHeight = canvasRefs.current.reduce(
      (height, canvas) => height + canvas.height,
      0
    );
    container.style.height = `${totalHeight}px`;
  };

  useEffect(() => {
    renderPdfPages();
    return () => {
      renderTasksRef.current.forEach((task) => task.cancel()); // Cancel any ongoing render tasks on unmount
    };
  }, [pdfDocument, refresh]);

  return (
    <div className='w-[999px] h-[659px] bg-white rounded-lg shadow border border-gray-200 flex-col justify-start items-start inline-flex'>
      <div className='self-stretch h-[88px] bg-white flex-col justify-start items-start flex'>
        <div className='self-stretch px-6 pt-5 pb-[19px] justify-start items-center gap-4 inline-flex'>
          <div className="grow shrink basis-0 text-neutral-500 text-[15px] font-bold font-['Manrope'] leading-tight">
            {fileName}
          </div>
          <button
            className='px-[22px] py-2 rounded border border-red-500 text-sm flex items-center space-x-2 text-red-500 cursor-pointer hover:bg-red-500 hover:text-white'
            onClick={handleDelete}
          >
            <div className='w-6 h-6 relative me-3'>
              <Delete />
            </div>
            <div>Delete file</div>
          </button>

          <button className='w-6 h-6 relative cursor-pointer' onClick={close}>
            <CloseOutlined />
          </button>
        </div>
        <div className='self-stretch h-px bg-gray-200' />
      </div>
      <div className='w-[999px] h-[570px] relative'>
        <div className='w-[226px] h-[982px] left-[773px] top-0 absolute bg-white pe-4' />
        <div className='w-[197px] left-[789px] top-[52px] absolute'>
          <span className="text-neutral-500 text-xs font-semibold font-['Manrope'] uppercase leading-relaxed tracking-wide">
            File Name
            <br />
          </span>
          <span className="text-neutral-500 text-[15px] font-normal font-['Manrope'] leading-tight">
            {fileName}
            <br />
            <br />
          </span>
          <span className="text-neutral-500 text-xs font-semibold font-['Manrope'] uppercase leading-relaxed tracking-wide">
            File Type <br />
          </span>
          <span className="text-neutral-500 text-[15px] font-normal font-['Manrope'] leading-tight">
            {fileDetails.type}
            <br />
            <br />
          </span>
          <span className="text-neutral-500 text-xs font-semibold font-['Manrope'] uppercase leading-relaxed tracking-wide">
            File Size
            <br />
          </span>
          <span className="text-neutral-500 text-[15px] font-normal font-['Manrope'] leading-tight">
            {fileDetails.size / 1000} KB
            <br />
          </span>
          <span className="text-neutral-500 text-xs font-semibold font-['Manrope'] uppercase leading-relaxed tracking-wide">
            <br />
            Last Modified
            <br />
          </span>
          <span className="text-neutral-500 text-[15px] font-normal font-['Manrope'] leading-tight">
            {modifiedTime}
            <br />
          </span>
          <span className="text-neutral-500 text-xs font-semibold font-['Manrope'] uppercase leading-relaxed tracking-wide">
            <br />
            Uploaded By
            <br />
          </span>
          <span className="text-neutral-500 text-[15px] font-normal font-['Manrope'] leading-tight">
            {uploadedBy ? uploadedBy : 'No Username found'}
          </span>
        </div>
        <div className="left-[789px] top-[24px] absolute text-black text-[15px] font-normal font-['Manrope'] leading-tight">
          File information
        </div>
        <div className='w-[773px] px-[31px] pt-[20px] left-0 top-0 absolute justify-center items-center inline-flex bg-stone-50'>
          {!pdfDocument && (
            <span>
              <img
                className='w-[711px] h-[920px] border-8 border-stone-50'
                src='https://via.placeholder.com/711x920'
              />
            </span>
          )}
          <div style={{ overflowY: 'scroll', height: '550px' }}>
            {Array.from({ length: pdfDocument ? pdfDocument.numPages : 0 }).map(
              (_, index) => (
                <canvas
                  key={index}
                  ref={(canvas) => (canvasRefs.current[index] = canvas)}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
