import React, { useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Scope from './Data-table/Scope-Datatable';
import TvIcon from '@mui/icons-material/Tv';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import PrintIcon from '@mui/icons-material/Print';

// const data = [
//   {
//     sno: '1',
//     scope: 'scope 1',
//     ageContribution: '58.75%',
//     totalemissions: '1284059.08',
//   },
//   {
//     sno: '2',
//     scope: 'scope 3',
//     ageContribution: '26.56%',
//     totalemissions: '228607.38',
//   },
//   {
//     sno: '3',
//     scope: 'scope 2',
//     ageContribution: '14.31%',
//     totalemissions: '99051.05',
//   },

//   // Add more data objects as needed
// ];

const ScopeTable = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log('newValue:', newValue);
    setValue(newValue);
  };
  const handleClick = ({ data }) => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className='my-4 mx-5 px-4'>
        <div>
          <h2 className='w-[633px] text-black text-opacity-90 text-[17px] font-bold leading-snug tracking-tight'>
            Top Emission by Scope
          </h2>
        </div>
        <div>
          <Scope data={data} />
        </div>
      </div>
    </>
  );
};
export default ScopeTable;
