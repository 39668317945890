import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Aside from 'components/collect/Aside';
import Emissions from 'components/collect/Emissions';
import Waste from 'components/collect/Waste';
import { useProSidebar } from 'react-pro-sidebar';
import { setActiveNav } from 'state';
import { useLocation } from 'react-router-dom';
import Energyconsumption from 'pages/Energy/energy-consumption/energy-consumption';
import Energyconsumptionoutside from 'pages/Energy/energy-consumption-outside/energy-consumption-outside';
const Environment = () => {
  const [activeTab, setActiveTab] = useState('Emissions');
  const { collapseSidebar, collapsed } = useProSidebar();
  const activeNav = useSelector((state) => state.global.activeNav);

  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split('/');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    dispatch(setActiveNav(tab));
  };

  useEffect(() => {
    console.log(parts, parts[parts.length - 1]);
    if (
      parts[parts.length - 1] === 'environment' &&
      (!activeNav || !['Emissions', 'Energy', 'Waste'].includes(activeNav))
    ) {
      setActiveTab('Emissions');
      dispatch(setActiveNav('Emissions'));
    }
    return () => {
      dispatch(setActiveNav(''));
    };
  }, []);

  return (
    <div className='flex overflow-x-hidden'>
      <Aside activeTab={activeTab} handleTabClick={handleTabClick} />
      <div
        className={`${
          collapsed ? 'min-w-[84%]' : 'min-w-[82%]'
        } transition-all me-0`}
      >
        {activeTab === 'Emissions' && <Emissions />}
        {/* {activeTab === 'Energy' && <Energy />} */}
        {activeTab === 'Waste' && <Waste />}
        {activeTab === 'Energy consumed inside the organization' && <Energyconsumption />}
        {activeTab === 'Energy consumption outside of the organization' && <Energyconsumptionoutside />}

      </div>
    </div>
  );
};

export default Environment;
