import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import { signUpSchema } from "../../components/schemas";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { registerUser } from "state/authSlice";
import { formatPhoneNumber } from "react-phone-number-input";
// import Toast from '../ToastMessage/ToastMessage';
import backicon from "../../assets/images/back-icon.svg";
import * as Yup from "yup";

const initialValues = {
  name: "",
  email: "",
  lastname: "",
  cname: "",
  jobtitle: "",
  phonenumber: "",
  mobile: "",
};

const SignupPersonalDetails = ({ onNextPage, onPreviousPage }) => {
  const [value, setValue] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [country, setCountry] = useState("US"); // Set a default country (e.g., United States)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastProperties, setToastProperties] = useState(null);
  //   const { dispatch } = useContext(AuthContext);
  const { values, errors, touched, handleBlur } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
  });
  console.log(
    "🚀 ~ file: Registration.jsx ~ line 25 ~ Registration ~ errors",
    errors
  );
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Use a regular expression to check if the input contains only alphabetic characters and spaces
    const isValidInput = /^[A-Za-z\s]*$/.test(value); // added \s to allow spaces
  
    if (isValidInput) {
      // Update the state or formik values if the input is valid
      formik.setFieldValue(name, value);
    }
  };
  

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is mandatory"),
    lastname: Yup.string().required("This field is mandatory"),
    mobile: Yup.string().required("Please enter a phone number"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      lastname: "",
      password: "",
      confirmPassword: "",
      termsAndCondition: false,
    },
    // onSubmit: (values) => {
    //     signup(
    //         {
    //             firstName: values.name,
    //             email: values.email,
    //             password: values.password,
    //         },
    //         dispatch
    //     )
    //         .then((res) => {
    //             setShowToast(true);
    //             res?.response?.status === 200 &&
    //                 setToastProperties({
    //                     type: 'Success',
    //                     message: `${res?.data?.message}`,
    //                 });
    //             res?.response?.status === 400 &&
    //                 setToastProperties({
    //                     type: 'Error',
    //                     message: `${res?.response?.data?.message}`,
    //                 });
    //             res?.response?.status === 200 && navigate('/login-form');
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // },
    validationSchema,
  });

  const preventCopyPaste = (e) => {
    e.preventDefault();
  };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     const credentials = {
  //       username: values.email,
  //       password: password,
  //       confirmPass: confirmPass,
  //     };
  //     dispatch(registerUser(credentials));
  //   };

  //   const handleClickShowPassword = () => setShowPassword((show) => !show);
  //   const handleClickShowPasswordCon = () => setConshowPassword((show) => !show);
  //   function confirmPassword(event) {
  //     setConfirmPassword(event.target.value);
  //     let val = event.target.value;
  //     console.log(confirmPass);
  //     if (password === val) {
  //       // console.log(confirmPass);
  //       setConfirmPass("New Password and Confirm Password are matched");

  //       setMassgecolor("rit");

  //       // setConfirmPass("Password matched");
  //     } else if (password == "") {
  //       setConfirmPass("");
  //     } else {
  //       // console.log(confirmPass);
  //       setConfirmPass("New Password and Confirm Password are not matched");
  //       setMassgecolor("worng");
  //     }
  //   }
  // useEffect(() => {
  //   if (country) {
  //     const country_name = Country.getAllCountries().filter((val) => {
  //       return val.isoCode == country;
  //     });
  //     // console.log(country_name[0].name)
  //     setCountryName(country_name[0].name);
  //   }
  // }, [country]);
  // useEffect(() => {
  //   if (state) {
  //     const states = State.getAllStates().filter((val) => {
  //       return val.name == state;
  //     });
  //     // console.log(states[0].isoCode)
  //     setStatecode(states[0].isoCode);
  //   }
  // }, [state]);
  // useEffect(() => {
  //   const getCities = async () => {
  //     try {
  //       const result = await City.getCitiesOfState(country, stateCode);
  //       let allCities = [];
  //       allCities = result?.map(({ name }) => ({
  //         name,
  //       }));
  //       // console.log(allCities,"rrrr")
  //       setCities(allCities);
  //     } catch (error) {
  //       setCities([]);
  //     }
  //   };

  //   getCities();
  // }, [state, stateCode, country]);

  //   const handleChangeCountry = (event) => {
  //     setCountry(event.target.value);
  //   };
  //   const handleChangeState = (event) => {
  //     setState(event.target.value);
  //   };
  //   const handleChangeCity = (event) => {
  //     setCity(event.target.value);
  //   };
  //   const [industry, setIndustry] = useState();
  //   const handleChangeindustry = (event) => {
  //     setIndustry(event.target.value);
  //   };

  return (
    <>
      <div className="min-h-screen place-items-center mt-10 to-teal-500 flex flex-wrap justify-center ">
        <div className="h-full bg-[#fff] w-[80%] ">
          <div className="flex flex-1 flex-col justify-center  py-2   ">
            <div className="">
              <div className="">
                {/* <Link to="/login"> */}
                <img
                  className="h-[2rem] hover:bg-sky-50 cursor-pointer"
                  src={backicon}
                  alt="Sustainext"
                  onClick={onPreviousPage}
                />
                {/* </Link> */}
              </div>
              <h2 className="mt-10  text-[24px] font-medium gradient-heading">
                Enter Personal Details
              </h2>
              <p className="mt-[10px] mb-4 text-start font-semibold text-[16px] text-color-login">
                Already have an account ?
                <Link
                  to="/login"
                  className="font-weight-400  ml-2 hover-underline-blue"
                  style={{ color: "#00AEEF" }}
                >
                  Log in
                </Link>
              </p>
            </div>

            {/* <hr className='border w-full' /> */}
            <div className="grid grid-cols-1 md:grid-cols-2 mb-6 space-y-2">
              <div className="mr-4 mt-2 mb-4">
                <label
                  htmlFor="name"
                  className="block text-[15px] leading-6 font-medium text-gray-900"
                >
                  First Name*
                </label>
                <div className="">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="name"
                    pattern="^[A-Za-z]+$" // Use the regex pattern to restrict to alphabetic characters
                    required
                    placeholder="Enter your first name"
                    className="block w-full text-sm rounded-md border-0 py-4 pl-4 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#00AEEF] sm:text-sm sm:leading-6"
                    value={formik.values.name}
                    onChange={handleChange} // Use the custom handleChange function
                    onBlur={formik.handleBlur}
                  />
                  <div className="error-wrapper">
                    {formik.touched.name && formik.errors.name ? (
                      <p className="errors text-red-500">
                        {formik.errors.name}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="lastname"
                  className="block text-[15px] font-medium leading-6 text-gray-900"
                >
                  Last Name*
                </label>
                <div className="">
                  <input
                    id="lastname"
                    name="lastname"
                    type="text"
                    // pattern="^[a-zA-Z]+$"
                    autoComplete="lastname"
                    required
                    placeholder="Enter last name"
                    className="block w-full text-sm rounded-md border-0 py-4 pl-4 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#00AEEF] sm:text-sm sm:leading-6"
                    value={formik.values.lastname}
                    onChange={handleChange} // Use the custom handleChange function
                    onBlur={formik.handleBlur}
                  />
                  <div className="error-wrapper">
                    {formik.touched.lastname && formik.errors.lastname ? (
                      <p className="errors text-red-500">
                        {formik.errors.lastname}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="mr-4 mt-6">
                <label
                  htmlFor="mobile"
                  className="block text-[15px] leading-6 text-gray-900"
                >
                  Phone Number
                </label>
                <div>
                  <div className="w-full ">
                    <PhoneInput
                    //   required
                    //   className="block flex px-4 text-sm rounded-md border-0 py-4 pl-4 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#00AEEF] sm:text-sm sm:leading-6"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      country={'us'}
                      placeholder="+1 (555) 000-0000"
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <p className="text-red-600">{formik.errors.mobile}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center pt-6">
              <div className="mr-2 w-[48.5%] submit-button">
                <button
                  onClick={onNextPage}
                  type="submit"
                  className="flex w-full justify-center rounded-md  px-3 py-2.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-[#42CC71] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignupPersonalDetails;
