import { createSlice } from '@reduxjs/toolkit';
import logo from 'assets/valenta-latest.png';

const initialState = {
  mode: 'dark',
  authToken: '',
  loginUser: localStorage.getItem('loginUser'),
  logo: {
    text: 'My Logo',
    image: logo,
  },
  activeNav: '',
  orgName: localStorage.getItem('orgName'),
  // userId: localStorage.getItem('user_id'),
  orgStructure: [],
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === 'light' ? 'dark' : 'light';
    },
    setLogo: (state, action) => {
      state.logo.image = action.payload;
    },
    setActiveNav: (state, action) => {
      state.activeNav = action.payload;
    },
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    setOrgStructure: (state, action) => {
      state.orgStructure = action.payload;
    },
    setLoginUser: (state, action) => {
      state.loginUser = action.payload;
    },
    // setUserid: (state, action) => {
    //   state.userId = action.payload;
    // },
  },
});

export const {
  setMode,
  setLogo,
  setActiveNav,
  setLoginUser,
  setOrgName,
  setOrgStructure,
  setUserid,
} = globalSlice.actions;

export default globalSlice.reducer;
