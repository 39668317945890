import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  ApartmentOutlined,
  InfoOutlined,
  KeyboardArrowDownOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { yearInfo } from "components/data/headerInfo";
import {
  setLocation,
  setYear,
  setQuarter,
  setCountryCode,
} from "state/emissionSlice";
import MonthButton from "components/MonthButton";
import { useNavigate } from "react-router-dom";
import { hierarchy } from "components/data/hierarchy";

const WasteHeaderSection = ({ monthsCalculated }) => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.authToken);

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-type": "application/json",
    },
  };

  // locations

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedYear, setSelectedYear] = useState("");

  const navigate = useNavigate();

  const year = useSelector((state) => state.emission.year);
  useEffect(() => {
    setSelectedYear(year);
  }, [year]);

  // const fetchLocations = () => {
  //   axios
  //     .get(
  //       'http://bmw.sustainext.staging.azte.in/api/v1/organisation/location-of-operation/',
  //       axiosConfig
  //     )
  //     .then((response) => {
  //       setLocations(response.data, 'locations');
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data: ', error);
  //     });
  // };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  useEffect(() => {
    fetchHierarchy();
  }, []);

  function fetchHierarchy() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/structure`, axiosConfig)
      .then((response) => {
        const data = response.data;
        const filtered = filterData(data);
        console.log(filtered);
        setLocations(filtered);
        setSelectedLocation(filtered[0]);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

  function filterData(data) {
    const extractedData = data.reduce((acc, item) => {
      const locations = item.corporatenetityorg.flatMap((entity) =>
        entity.location.map((location) => ({
          id: location.id,
          name: location.name,
          Address: location.address,
          Country: location.country,
          state: location.state,
          revenue: location.revenue,
          amount: location.amount,
          area: location.area,
          sector: location.sector,
          City: location.city,
          "Corporate Entity": location.corporate_entity,
          businessActivities: location.type_of_business_activities,
          productTypes: location.type_of_product,
          serviceTypes: location.type_of_services,
        }))
      );
      return [...acc, ...locations];
    }, []);

    return extractedData;
  }

  // function extractLocations(hData) {
  //   const allLocations = [];
  //   hData.forEach((org) => {
  //     org.corporate_entities.forEach((entity) => {
  //       entity.location_of_operations.forEach((location) => {
  //         allLocations.push(location);
  //       });
  //     });
  //   });
  //   setLocations(allLocations);
  // }

  // useEffect(() => {
  //   // fetchHierarchy();
  //   extractLocations(hierarchy);
  // }, []);

  // useEffect(() => {
  //   fetchLocations();
  // }, []);

  //quarter
  // const [quarter, setQuarterState] = useState('');
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const handleMonthClick = (month) => {
    dispatch(setQuarter(month));
  };

  const handleLocationChange = (event) => {
    const location = event.target.value;
    dispatch(setLocation(location));
    const foundLocation = locations.find((loc) => loc.name === location);
    setSelectedLocation(foundLocation);

    const code = foundLocation.country;
    console.log({ foundLocation });
    dispatch(setCountryCode(code));
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    dispatch(setYear(year));
    setSelectedYear(year);
  };

  return (
    <div className="flex flex-col justify-start mx-2 mb-14">
      <div className="flex justify-between  items-center mb-6">
        <div className="border-r w-full">
          <h1 className="gradient-text mb-4 text-[1.375rem] font-bold">
            Waste
          </h1>
          <h5 className="font-lg font-medium text-gray-500 text-[0.625rem] -mt-4 ">
            <InfoOutlined style={{ fontSize: "0.625rem" }} />
            The data that falls under waste category are uploaded here.
          </h5>
        </div>
        <div className="">
            <button className="text-[#007EEF] waste-button-class w-[80px] h-[25px] mx-8">GRI-306</button>
        </div>
      </div>
      <div className="border-t">
        <div className="flex space-x-4 mt-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <LocationOnOutlined
                className="text-neutral-500"
                style={{ fontSize: "16px" }}
              />
            </div>
            <select
              className="border m-0.5 text-sm text-neutral-500 appearance-none pr-8 rounded-md py-2 pl-10 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              onChange={handleLocationChange}
            >
              <option value="">Select Location</option>
              {locations.map(({ name }) => (
                <option value={name}>{name}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "16px" }}
              />
            </div>
          </div>
          <div className="relative ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <CalendarMonthOutlined
                className="text-neutral-500"
                style={{ fontSize: "16px" }}
              />
            </div>
            <select
              className="w-[152px] border appearance-none text-sm text-neutral-500 m-0.5 pr-8 rounded-md py-2 pl-10 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="">Select Year</option>
              {yearInfo.map((item) => (
                <option value={item.slice(0, 4)}>{item}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "16px" }}
              />
            </div>
          </div>
          <div className="absolute -right-10 w-[400px] h-6 px-1 py-[3px] bg-white bg-opacity-10 rounded-lg justify-start items-center inline-flex">
            {/* <div className='text-neutral-500 text-sm font-bold leading-[15px] flex'>
              <ApartmentOutlined className='me-1 -mt-1' />
              <div>
                Corporate Entity:{' '}
                {selectedLocation.name && (
                  <span className='text-neutral-500 border px-2 py-1 rounded-lg border-stone-300'>
                    {selectedLocation?.corporate_entity?.legal_name}{' '}
                  </span>
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex justify-center items-center mt-6  w-[775px] h-9 rounded-md">
          {months.map((month) => (
            <MonthButton
              month={month}
              handleMonthClick={handleMonthClick}
              monthsCalculated={monthsCalculated}
            />
          ))}
        </div>
      </div>
      <div>
        {/* <h4 className='font-lg font-medium mb-4 ms-2'>Corporate Entity :</h4>
        {selectedLocation.name && (
          <p className='text-[#007EEF] border px-2 py-1 rounded-md'>
            {selectedLocation?.corporate_entity?.legal_name}
          </p>
        )} */}
      </div>
    </div>
  );
};

export default WasteHeaderSection;
